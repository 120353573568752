var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Basic", "code-toggler": "" } },
    [
      _c("div", { staticClass: "mb-base" }, [
        _c("p", [
          _c("code", [_vm._v("vx-input-group")]),
          _vm._v(
            " component provides various options to create desired form input group."
          ),
        ]),
      ]),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("@")]),
            ]),
          ]),
          _c("vs-input", {
            attrs: { placeholder: "Email" },
            model: {
              value: _vm.title,
              callback: function ($$v) {
                _vm.title = $$v
              },
              expression: "title",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("vs-input", {
            attrs: { placeholder: "Username" },
            model: {
              value: _vm.username,
              callback: function ($$v) {
                _vm.username = $$v
              },
              expression: "username",
            },
          }),
          _c("template", { slot: "append" }, [
            _c("div", { staticClass: "append-text bg-primary" }, [
              _c("span", [_vm._v("@url.com")]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("https://pixinvent.com/users/")]),
            ]),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.siteUsername,
              callback: function ($$v) {
                _vm.siteUsername = $$v
              },
              expression: "siteUsername",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("$")]),
            ]),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.amount,
              callback: function ($$v) {
                _vm.amount = $$v
              },
              expression: "amount",
            },
          }),
          _c("template", { slot: "append" }, [
            _c("div", { staticClass: "append-text bg-primary" }, [
              _c("span", [_vm._v(".00")]),
            ]),
          ]),
        ],
        2
      ),
      _c(
        "vx-input-group",
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("Description")]),
            ]),
          ]),
          _c("vs-textarea", {
            model: {
              value: _vm.textarea,
              callback: function ($$v) {
                _vm.textarea = $$v
              },
              expression: "textarea",
            },
          }),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<tempalte>\n  <!-- TITLE -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>@</span>\n      </div>\n    </template>\n\n    <vs-input v-model="title" placeholder="Email" />\n  </vx-input-group>\n  <!-- /TITLE -->\n\n  <!-- URL -->\n  <vx-input-group class="mb-base">\n    <vs-input v-model="username" placeholder="Username" />\n\n    <template slot="append">\n      <div class="append-text bg-primary">\n        <span>@url.com</span>\n      </div>\n    </template>\n  </vx-input-group>\n  <!-- /URL -->\n\n  <!-- USERNAME -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>https://pixinvent.com/users/</span>\n      </div>\n    </template>\n\n    <vs-input v-model="siteUsername" />\n  </vx-input-group>\n  <!-- /USERNAME -->\n\n  <!-- currency -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>$</span>\n      </div>\n    </template>\n\n    <vs-input v-model="amount" />\n\n    <template slot="append">\n      <div class="append-text bg-primary">\n        <span>.00</span>\n      </div>\n    </template>\n  </vx-input-group>\n  <!-- /currency -->\n\n  <!-- Textarea -->\n  <vx-input-group>\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>Description</span>\n      </div>\n    </template>\n\n    <vs-textarea v-model="textarea" />\n  </vx-input-group>\n  <!-- /Textarea -->\n</tempalte>\n\n<script>\nexport default {\n  data() {\n    return {\n      title: \'\',\n      username: \'\',\n      siteUsername: \'\',\n      amount: \'\',\n      textarea: \'\',\n    }\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }