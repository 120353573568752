var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Button addons", "code-toggler": "" } },
    [
      _c("div", { staticClass: "mb-base" }, [
        _c("span", [_vm._v("You can also use button as addons to your input")]),
      ]),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "div",
              { staticClass: "prepend-text btn-addon" },
              [
                _c("vs-button", { attrs: { color: "primary" } }, [
                  _vm._v("Button"),
                ]),
              ],
              1
            ),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input1,
              callback: function ($$v) {
                _vm.input1 = $$v
              },
              expression: "input1",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("vs-input", {
            model: {
              value: _vm.input2,
              callback: function ($$v) {
                _vm.input2 = $$v
              },
              expression: "input2",
            },
          }),
          _c("template", { slot: "append" }, [
            _c(
              "div",
              { staticClass: "append-text btn-addon" },
              [
                _c("vs-button", { attrs: { color: "primary" } }, [
                  _vm._v("Button"),
                ]),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "div",
              { staticClass: "prepend-text btn-addon" },
              [
                _c(
                  "vs-button",
                  { attrs: { color: "primary", type: "border" } },
                  [_vm._v("Button1")]
                ),
                _c(
                  "vs-button",
                  { attrs: { color: "primary", type: "border" } },
                  [_vm._v("Button2")]
                ),
              ],
              1
            ),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input3,
              callback: function ($$v) {
                _vm.input3 = $$v
              },
              expression: "input3",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("vs-input", {
            model: {
              value: _vm.input4,
              callback: function ($$v) {
                _vm.input4 = $$v
              },
              expression: "input4",
            },
          }),
          _c("template", { slot: "append" }, [
            _c(
              "div",
              { staticClass: "append-text btn-addon" },
              [
                _c(
                  "vs-button",
                  { attrs: { color: "primary", type: "border" } },
                  [_vm._v("Button1")]
                ),
                _c(
                  "vs-button",
                  { attrs: { color: "primary", type: "border" } },
                  [_vm._v("Button2")]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <!-- Basic -->\n    <vx-input-group class="mb-base">\n      <template slot="prepend">\n        <div class="prepend-text btn-addon">\n          <vs-button color="primary">Button</vs-button>\n        </div>\n      </template>\n\n      <vs-input v-model="input1" />\n    </vx-input-group>\n    <!-- /Basic -->\n\n    <!-- Append Button -->\n    <vx-input-group class="mb-base">\n      <vs-input v-model="input2" />\n\n      <template slot="append">\n        <div class="append-text btn-addon">\n          <vs-button color="primary">Button</vs-button>\n        </div>\n      </template>\n    </vx-input-group>\n    <!-- /Append Button -->\n\n    <!-- Prepend Two Buttons -->\n    <vx-input-group class="mb-base">\n      <template slot="prepend">\n        <div class="prepend-text btn-addon">\n          <vs-button color="primary" type="border">Button1</vs-button>\n          <vs-button color="primary" type="border">Button2</vs-button>\n        </div>\n      </template>\n\n      <vs-input v-model="input3" />\n    </vx-input-group>\n    <!-- /Prepend Two Buttons -->\n\n    <!-- Append Two Buttons -->\n    <vx-input-group class="mb-base">\n      <vs-input v-model="input4" />\n\n      <template slot="append">\n        <div class="append-text btn-addon">\n          <vs-button color="primary" type="border">Button1</vs-button>\n          <vs-button color="primary" type="border">Button2</vs-button>\n        </div>\n      </template>\n    </vx-input-group>\n    <!-- /Append Two Buttons -->\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      input1: \'\',\n      input2: \'\',\n      input3: \'\',\n      input4: \'\'\n    }\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }