var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "input-group-demo" } },
    [
      _c("form-input-group-basic"),
      _c("form-input-group-sizing"),
      _c("form-input-group-form-elements"),
      _c("form-input-group-multiple-addons"),
      _c("form-input-group-button-addons"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }