var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Form Elements", "code-toggler": "" } },
    [
      _c("div", { staticClass: "mb-base" }, [
        _c("p", [
          _vm._v(
            "You can also add form elements to prepend/append slot. Using "
          ),
          _c("strong", [_vm._v("tailwind css")]),
          _vm._v(", you can tune appearance as you like."),
        ]),
      ]),
      _c(
        "vx-input-group",
        {
          staticClass: "mb-base form-element-demo",
          attrs: {
            prependClasses:
              "border border-solid form-element-border border-r-0",
          },
        },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "div",
              { staticClass: "prepend-text" },
              [
                _c("vs-checkbox", {
                  model: {
                    value: _vm.checkbox1,
                    callback: function ($$v) {
                      _vm.checkbox1 = $$v
                    },
                    expression: "checkbox1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input1,
              callback: function ($$v) {
                _vm.input1 = $$v
              },
              expression: "input1",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        {
          staticClass: "mb-base form-element-demo",
          attrs: {
            prependClasses:
              "border border-solid form-element-border border-r-0",
          },
        },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "div",
              { staticClass: "prepend-text" },
              [
                _c("vs-radio", {
                  attrs: { "vs-value": "radio1" },
                  model: {
                    value: _vm.radios1,
                    callback: function ($$v) {
                      _vm.radios1 = $$v
                    },
                    expression: "radios1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input2,
              callback: function ($$v) {
                _vm.input2 = $$v
              },
              expression: "input2",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        {
          staticClass: "mb-base form-element-demo",
          attrs: {
            prependClasses:
              "border border-solid form-element-border border-r-0",
          },
        },
        [
          _c("template", { slot: "prepend" }, [
            _c(
              "div",
              { staticClass: "prepend-text" },
              [
                _c("vs-switch", {
                  model: {
                    value: _vm.switch1,
                    callback: function ($$v) {
                      _vm.switch1 = $$v
                    },
                    expression: "switch1",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input3,
              callback: function ($$v) {
                _vm.input3 = $$v
              },
              expression: "input3",
            },
          }),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <!-- CHECKBOX -->\n  <vx-input-group class="mb-base" prependClasses="border border-solid border-grey border-r-0">\n    <template slot="prepend">\n      <div class="prepend-text">\n        <vs-checkbox v-model="checkbox1"></vs-checkbox>\n      </div>\n    </template>\n\n    <vs-input v-model="input1" />\n  </vx-input-group>\n  <!-- /CHECKBOX -->\n\n  <!-- RADIO -->\n  <vx-input-group class="mb-base" prependClasses="border border-solid border-grey border-r-0">\n    <template slot="prepend">\n      <div class="prepend-text">\n        <vs-radio v-model="radios1" vs-value="radio1"></vs-radio>\n      </div>\n    </template>\n\n    <vs-input v-model="input2" />\n  </vx-input-group>\n  <!-- /RADIO -->\n\n  <!-- SWITCH -->\n  <vx-input-group class="mb-base" prependClasses="border border-solid border-grey border-r-0">\n    <template slot="prepend">\n      <div class="prepend-text">\n        <vs-switch v-model="switch1" />\n      </div>\n    </template>\n\n    <vs-input v-model="input3" />\n  </vx-input-group>\n  <!-- /SWITCH -->\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      checkbox1: false,\n      radios1: null,\n      switch1: false,\n      input1: \'\',\n      input2: \'\',\n      input3: \'\'\n    }\n  }\n}\n</script>\n\n<style lang="scss">\n.vx-input-group {\n  .vx-input-group-prepend.form-element-border {\n    border-color: rgba(0,0,0,0.25);\n  }\n\n}\n\n.theme-dark {\n  .vx-input-group {\n    .vx-input-group-prepend.form-element-border {\n      border-color: rgba(255,255,255,0.25);\n    }\n\n    .vs-inputx {\n      border-color: rgba(255,255,255,0.25) !important;\n    }\n  }\n}\n</style>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }