var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Sizing", "code-toggler": "" } },
    [
      _c("div", { staticClass: "mb-base" }, [
        _c("span", [
          _vm._v("You can use "),
          _c("code", [_vm._v("size")]),
          _vm._v(" prop of "),
          _c("code", [_vm._v("vs-input")]),
          _vm._v(" component to change size of input group."),
        ]),
      ]),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("Small")]),
            ]),
          ]),
          _c("vs-input", {
            attrs: { size: "small" },
            model: {
              value: _vm.input1,
              callback: function ($$v) {
                _vm.input1 = $$v
              },
              expression: "input1",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("Default")]),
            ]),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.input2,
              callback: function ($$v) {
                _vm.input2 = $$v
              },
              expression: "input2",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("Large")]),
            ]),
          ]),
          _c("vs-input", {
            attrs: { size: "large" },
            model: {
              value: _vm.input3,
              callback: function ($$v) {
                _vm.input3 = $$v
              },
              expression: "input3",
            },
          }),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <!-- SMALL -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>Small</span>\n      </div>\n    </template>\n\n    <vs-input v-model="input1" size="small" />\n  </vx-input-group>\n  <!-- /SMALL -->\n\n  <!-- DEFAULT -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>Default</span>\n      </div>\n    </template>\n\n    <vs-input v-model="input2" />\n  </vx-input-group>\n  <!-- /DEFAULT -->\n\n  <!-- LARGE -->\n  <vx-input-group class="mb-base">\n    <template slot="prepend">\n      <div class="prepend-text bg-primary">\n        <span>Large</span>\n      </div>\n    </template>\n\n    <vs-input v-model="input3" size="large" />\n  </vx-input-group>\n  <!-- /LARGE -->\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      input1: \'\',\n      input2: \'\',\n      input3: \'\'\n    }\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }