var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "vx-card",
    { attrs: { title: "Multiple Addons", "code-toggler": "" } },
    [
      _c("div", { staticClass: "mb-base" }, [
        _c("p", [
          _c("code", [_vm._v("vx-input-group")]),
          _vm._v(
            " component provides various options to create desired form input group."
          ),
        ]),
      ]),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "prepend" }, [
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("$")]),
            ]),
            _c("div", { staticClass: "prepend-text bg-primary" }, [
              _c("span", [_vm._v("0.00")]),
            ]),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.title1,
              callback: function ($$v) {
                _vm.title1 = $$v
              },
              expression: "title1",
            },
          }),
        ],
        2
      ),
      _c(
        "vx-input-group",
        { staticClass: "mb-base" },
        [
          _c("template", { slot: "append" }, [
            _c("div", { staticClass: "append-text bg-primary" }, [
              _c("span", [_vm._v("$")]),
            ]),
            _c("div", { staticClass: "append-text bg-primary" }, [
              _c("span", [_vm._v("0.00")]),
            ]),
          ]),
          _c("vs-input", {
            model: {
              value: _vm.title2,
              callback: function ($$v) {
                _vm.title2 = $$v
              },
              expression: "title2",
            },
          }),
        ],
        2
      ),
      _c("template", { slot: "codeContainer" }, [
        _vm._v(
          '\n<template>\n  <div>\n    <!-- ADDONS PREPEND -->\n    <vx-input-group class="mb-base">\n      <template slot="prepend">\n        <div class="prepend-text bg-primary">\n          <span>$</span>\n        </div>\n        <div class="prepend-text bg-primary">\n          <span>0.00</span>\n        </div>\n      </template>\n\n      <vs-input v-model="title1" />\n    </vx-input-group>\n    <!-- /ADDONS PREPEND -->\n\n    <!-- ADDONS APPEND -->\n    <vx-input-group class="mb-base">\n      <template slot="append">\n        <div class="append-text bg-primary">\n          <span>$</span>\n        </div>\n        <div class="append-text bg-primary">\n          <span>0.00</span>\n        </div>\n      </template>\n\n      <vs-input v-model="title2" />\n    </vx-input-group>\n    <!-- /ADDONS APPEND -->\n  </div>\n</template>\n\n<script>\nexport default {\n  data() {\n    return {\n      title1: \'\',\n      title2: \'\',\n    }\n  }\n}\n</script>\n    '
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }